import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-plugin-wave',
  templateUrl: './plugin-wave.component.html',
  styleUrls: ['./plugin-wave.component.scss']
})
export class PluginWaveComponent implements OnInit {

  youtube : string[] = [
    "SFGxSbgRP4c", "hvJNC66DMc0", "Mq4dx5e1pHU"
  ];

  plugin = [/*{
    oldPrice: "",
    price: "₹0",
    period: "Life Time",
    paymentType: 'link',
    payment: "https://www.tradingview.com/script/NabzMpFJ-NEoWave-Chart-Free-Version/",
    msg: "Lifetime access that allow you to check daily timeframe wave chart only."
  },
    {
    oldPrice: "₹2,500",
    price: "₹1,500",
    offerPrice: "₹33,000",
    period: "1 Month",
    offer: "", // Extra 1 Week Free
    offerEnd: "Offer ends on 31st Aug",
    paymentType: 'both',
    upi: "intellxis@hdfcbank,INTELLXIS LLP",
    paymentLink: "https://pages.razorpay.com/pl_L22PBFvAGflnLs/view",
    msg: "* You can subscribe for one month trial version only once."
  }, */
  {
    oldPrice: "₹9,000",
    price: "₹7,500",
    offerPrice: "₹33,000",
    period: "6 Month",
    offer: "", //Extra 6 Months Free
    offerEnd: "Offer ends on 31st Aug",
    paymentType: 'both',
    upi: "intellxis@hdfcbank,INTELLXIS LLP",
    paymentLink: "https://pages.razorpay.com/pl_L22NQrHY0FexYD/view",
    msg: "Subscribe our premium version of wave chart plugin for 6 Months."
  }, {
    oldPrice: "₹18,000",
    price: "₹9,000",
    offerPrice: "₹33,000",
    period: "12 Month",
    offer: "", //Extra 12 Months Free
    offerEnd: "Offer ends on 31st Aug",
    paymentType: 'both',
    upi: "intellxis@hdfcbank,INTELLXIS LLP",
    paymentLink: "https://pages.razorpay.com/pl_L22OS3vqT0ThYw/view",
    msg: "Subscribe our premium version of wave chart plugin for 12 Months."
  }, {
    oldPrice: "₹XXXXXXX",
    price: "₹33,000",
    period: "25 Years",
    offer: "", //Offer ends
    offerPrice: "₹33,000",
    offerEnd: "on 31st Aug",
    paymentType: 'both',
    upi: "intellxis@hdfcbank,INTELLXIS LLP",
    paymentLink: "https://rzp.io/l/neowave-plugin-5year",
    msg: "Subscribe our premium version of wave chart plugin for Lifetime."
  }
  ];

  constructor(private sanitizer: DomSanitizer, public changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  public getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
