<main>
  <app-breadcrumb title="Educational Forecasting Service" subtitle="NEoWave Forecasting"
                  backImage="assets/img/page-title/forecasting.jpg">
  </app-breadcrumb>

  <!--  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:250px;color: transparent;background-color: transparent;"
     class="icon_check _animate_pulse"></i>
  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:250px;color: transparent;background-color: transparent"
     class="icon_check _animate_pulse-3"></i>
  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:250px;color: transparent;background-color: transparent"
     class="icon_check _animate_pulse-5"></i>-->


  <section class="event__area">
    <div class="container mt-50">
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8">
          <div class="container ">
            <div class="row align-items-center">
              <div class="col-xxl-12 col-xl-12 col-lg-12 text-center">
                <div class="section__title-wrapper">
                  <h3 class="section__title">
                    Educational <span class="yellow-bg yellow-bg-big">Forecasting
                  <img src="assets/img/shape/yellow-bg.png" alt=""></span> Service</h3>
                  <h3 class="mt-15 mb-15 ">In our forecasting service we covers</h3>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-xxl-12 col-xl-12 col-lg-12 text-center">
                <p class="font-weight-300 text-18 text-align-justify text-secondary pb-0 mb-0 ">
                  Subscribing to educational forecasting services can be a great way to improve your forecasting
                  skills and enhance your ability to make better predictions in the market. These services can teach you
                  valuable techniques and strategies for analyzing market trends and data, and provide you with insights
                  and guidance on how to make accurate predictions.
                </p>
                <p class="mt-15 text-18 font-weight-300 text-align-justify text-secondary pb-0 mb-0 ">
                  These services can be beneficial for individuals who want to improve their understanding of market
                  trends and enhance their forecasting skills. The focus on wave analysis can be particularly useful for
                  identifying patterns and trends in the market, which can inform better predictions and
                  decision-making.
                </p>
                <p class="mt-15 text-18 font-weight-300 text-align-justify text-secondary pb-0 mb-0 ">
                  Overall, if you're looking to improve your forecasting skills and become a more effective market
                  predictor, subscribing to an educational forecasting service can be a smart investment in your
                  professional development and growth.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4">
          <div class="events__sidebar text-center p-25">
            <div class="_animate_pulse_black">
              <div class="events__sidebar-widget white-bg">
                <div class="events__sidebar-shape">
                  <img class="events-sidebar-img-2 _animate_dot_up" src="assets/img/shape/event-shape-2.png" alt="">
                  <img class="events-sidebar-img-3 _animate_dot_updown" src="assets/img/shape/event-shape-3.png" alt="">
                </div>
                <h4 class="border-bottom">{{ forecasting[3].period }}</h4>
                <div class="events__info">
                  <div class="mb-25 ">
                    <h4>{{ forecasting[3].priceMonth }}<span
                      class="text-secondary font-weight-500 text-18"></span></h4>
                    <h5><span class="text-secondary font-weight-500 text-15"><s>{{ forecasting[3].oldPrice }}</s></span>
                    </h5>
                    <h5 class="animate__animated animate__infinite animate__pulseIn">Pay
                      Just {{ forecasting[3].priceTotal }}</h5>
                    <!--<div class="events__info-discount">
                      <span>68% OFF</span>
                    </div>-->
                  </div>
                  <div *ngIf="forecasting[3].upi"
                       class="mb-25 border border-2 pt-10 pb-10 animate__animated animate__infinite animate__pulseIn">
                    <h4>Pay Direct using UPI</h4>
                    <h4>{{ forecasting[3].upi }} only<span class="text-secondary font-weight-500 text-15"></span></h4>
                    <h4 class="text-gray-500">UPI Payment ID</h4>
                    <h4>intellxis@hdfcbank</h4>
                    <h4>INTELLXIS LLP</h4>
                    <div class="events__info-discount">
                      <span class="text-secondary">Contact us<br/>before Payment</span><br/>
                      <span class="text-secondary">+(91) 947 99 99 88 7</span>
                    </div>
                  </div>
                  <div class="events__info-content mb-35">
                    <ul>
                      <li>
                        <div class="events__info-item"><h5><span>2 times is a week</span></h5></div>
                      </li>
                      <li>
                        <div class="events__info-item"><h5><span>Wednesday & Saturday</span></h5></div>
                      </li>
                      <li>
                        <div class="events__info-item"><h5><span>Free* Wave Chart Plugin</span></h5></div>
                      </li>
                      <li><p class="text-black animate__animated animate__infinite animate__pulseIn">By
                        <b>Subscribing</b> the service, you are accepting the <a
                          [routerLink]="['/terms-of-service']"><u>Term
                          & Conditions</u></a></p></li>
                    </ul>
                  </div>
                  <a target="_blank" href="{{forecasting[3].payment}}"
                     class="btn align-items-end align-self-end border-radius-0 btn-primary">Subscr<span
                    class="_animate_pulse"></span>ibe Now</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <section class="course__area mt-50 mb-50">
        <div class="container">
          <div class="row feature-icons mt-5" data-aos="fade-up">

            <div class="col-xl-12 d-flex content">
              <div class="row align-self-center align-items-center gy-4">

                <div class="col-md-6 align-self-center" data-aos="fade-up" data-aos-delay="100">
                  <img height="64" width="64" src="assets/img/forecasting/006-results.png" alt=""/>
                  <div class="mt-15 pb-0 mb-0 pl-75 mt--65">
                    <h4>Economic Data Updates</h4>
                    <p class="text-18  font-weight-300 text-align-justify text-secondary">
                      Economic data updates are a critical component of market analysis, as they
                      provide valuable insights into the health and performance of the economy.
                      Subscribers to Intellxis' forecasting service will receive regular updates on
                      key economic indicators, such as inflation, GDP growth, and unemployment rates,
                      etc.
                    </p>
                  </div>
                </div>

                <div class="col-md-6 align-self-center" data-aos="fade-up" data-aos-delay="200">
                  <img height="64" width="64" src="assets/img/forecasting/003-global.png" alt=""/>
                  <div class="mt-15 pb-0 mb-0 pl-75 mt--65">
                    <h4>News & Update</h4>
                    <p class="text-18  font-weight-300 text-align-justify text-secondary">
                      Understanding the performance of different sectors within the market can be
                      crucial to making accurate predictions and informed investment decisions. As
                      part of this service, Intellxis provides regular updates and analysis on
                      different sectors within the market, such as healthcare, technology, and energy
                      etc.
                    </p>
                  </div>
                </div>

                <div class="col-md-6 align-self-center" data-aos="fade-up" data-aos-delay="400">
                  <img height="64" width="64" src="assets/img/forecasting/001-indicator.png" alt=""/>
                  <div class="mt-15 pb-0 mb-0 pl-75 mt--65">
                    <h4>Analysis of Indices & Sector</h4>
                    <p class="text-18  font-weight-300 text-align-justify text-secondary">
                      Intellxis will analyze major indices such as Nifty and Bank Nifty as well as
                      sector-specific indices like Nifty IT and Nifty Pharma. This can provide
                      valuable insights into how different segments of the market are performing and
                      help subscribers make informed investment decisions.
                    </p>
                  </div>
                </div>

                <div class="col-md-6 align-self-center" data-aos="fade-up" data-aos-delay="500">
                  <img height="64" width="64" src="assets/img/forecasting/005-commodity.png" alt=""/>
                  <div class="mt-15 pb-0 mb-0 pl-75 mt--65">
                    <h4>Analysis of Commodities</h4>
                    <p class="text-18  font-weight-300 text-align-justify text-secondary">
                      Commodities, such as gold and silver, can be an important component of many
                      investment portfolios. As part of this service, Intellxis provides analysis and
                      insights into the performance of different commodities, as well as factors that
                      may be affecting their value.
                    </p>
                  </div>
                </div>

                <div class="col-md-6 align-self-center" data-aos="fade-up">
                  <img height="64" width="64" src="assets/img/forecasting/004-live.png" alt=""/>
                  <div class="mt-15 pb-0 mb-0 pl-75 mt--65">
                    <h4>Market Analysis Twice a Week</h4>
                    <p class="text-18  font-weight-300 text-align-justify text-secondary">
                      As part of this service, Intellxis offers two market analysis sessions per week, providing
                      subscribers with recorded access to expert insights and in-depth analysis. This is a great way to
                      stay informed on the latest market trends and developments.
                    </p>
                  </div>
                </div>

                <div class="col-md-6 align-self-center" data-aos="fade-up" data-aos-delay="300">
                  <img height="64" width="64" src="assets/img/forecasting/002-currency-exchange.png" alt=""/>
                  <div class="mt-15 pb-0 mb-0 pl-75 mt--65">
                    <h4>Foreign Indices & Currencies</h4>
                    <p class="text-18  font-weight-300 text-align-justify text-secondary">
                      Finally, as part of this service, Intellxis also provides analysis and insights
                      into foreign markets, including major indices and currencies. This can be
                      particularly useful for investors who are looking to diversify their portfolios
                      and gain exposure to global markets.
                    </p>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>
    </div>

    <!--<section class="slider__area  p-relative bg-primary">
      <div class="slider__wrapper swiper-container">
        <img width="100%" src="assets/img/hero/offer.png" alt="">
      </div>
    </section>-->

    <!--    <section class="course__area mt-50 pb-30">
          <div class="container">
            <app-forecasting-videos></app-forecasting-videos>
          </div>
        </section>-->

    <section class="course__area">
      <div class="container border-top pt-30">
        <h2 class="text-25 course__title mb-30 text-center">Choose The
          Plan That's Right For You</h2>
        <div class="row">
          <div *ngFor="let forecast of forecasting; let i = index" class="pl-30 pr-30 col-xxl-3 col-xl-3 col-lg-3">
            <div class="events__sidebar text-center">
              <div class="events__sidebar-widget white-bg mb-20 border-3 border-primary"
                   [ngClass]="{'border animate__animated animate__infinite _animate_pulse_black':forecast.offer}">
                <div class="events__sidebar-shape">
                  <img class="events-sidebar-img-2" src="assets/img/shape/event-shape-2.png" alt="">
                  <img class="events-sidebar-img-3"
                       [ngClass]="{'_animate_dot_up': i % 2 !== 0, '_animate_dot_down2': i % 2 == 0}"
                       src="assets/img/shape/event-shape-3.png" alt="">
                </div>
                <h3 class="p-5 border-bottom">{{ forecast.period }}</h3>
                <div class="events__info">
                  <div class="mb-25 ">
                    <h4>{{ forecast.priceMonth }}<span
                      class="text-secondary font-weight-500 text-18"></span></h4>
                    <h5 *ngIf="forecast.oldPrice"><span
                      class="text-secondary font-weight-500 text-15"><s>{{ forecast.oldPrice }}</s></span></h5>
                    <h5 *ngIf="!forecast.offer"
                        class="animate__animated animate__infinite animate__pulseIn text-strike">Pay
                      Just {{ forecast.priceTotal }}</h5>
                    <h5 *ngIf="forecast.offer" class="animate__animated animate__infinite animate__pulseIn text-strike">
                      <s>{{ forecast.priceTotal }}</s></h5>
                    <h5 *ngIf="forecast.offer" class="animate__animated animate__infinite animate__pulseIn text-strike">
                      Pay Just
                      {{ forecast.offerPrice }}
                    </h5>                    <!--<div class="events__info-discount">
                      <span>68% OFF</span>
                    </div>-->
                  </div>
                  <div *ngIf="forecast.upi" class="mb-25 border-bottom border-top pt-10 pb-10 _animate_pulse_black">
                    <h4>Pay using UPI</h4>
                    <h4 class="animate__animated animate__infinite animate__pulseIn">{{ forecast.upi }} only<span
                      class="text-secondary font-weight-500 text-15"></span></h4>
                    <h4 class="text-gray-500">UPI Payment ID</h4>
                    <h4>intellxis@hdfcbank</h4>
                    <h4>INTELLXIS LLP</h4>
                    <div class="events__info-discount">
                      <span class="text-secondary">Contact us<br/>before Payment</span><br/>
                      <span class="text-secondary">+(91) 947 99 99 88 7</span>
                    </div>
                  </div>
                  <div class="events__info-content mb-35">
                    <ul>
                      <li>
                        <div class="events__info-item"><h5><span>2 times is a week</span></h5></div>
                      </li>
                      <li>
                        <div class="events__info-item"><h5><span>Wednesday & Saturday</span></h5></div>
                      </li>
                      <li>
                        <div class="events__info-item"><h5><span>Free* Wave Chart Plugin</span></h5></div>
                      </li>
                      <li><p class="text-black animate__animated animate__infinite animate__pulseIn">By
                        <b>Subscribing</b> the service, you are accepting the <a
                          [routerLink]="['/terms-of-service']"><u>Term
                          & Conditions</u></a></p></li>
                    </ul>
                  </div>
                  <a target="_blank" href="{{forecast.payment}}"
                     class="btn align-items-end align-self-end border-radius-0 btn-primary">Subscr<span
                    class="_animate_pulse"></span>ibe Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="course__area mb-50">
      <div class="container border-top pt-30">
        <p>* You will get free access of wave chart plugin only if you don't have prior access of it.</p>
        <h4><a [routerLink]="['/terms-of-service']">Term & Conditions</a></h4>
      </div>
    </section>

  </section>
</main>
